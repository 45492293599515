import React from "react";
import "./App.scss";

const ErrorPage = () => {
	return (
		<div className="container">
			<div className="main-content">
				<div className="status-container">
					<h1>HUBO UN ERROR</h1>
					<p>
						No pudimos procesar tu suscripción. Por favor, inténtalo de nuevo.
					</p>
				</div>
			</div>
		</div>
	);
};

export default ErrorPage;
