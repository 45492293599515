import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
} from "react-router-dom";
import "./App.scss";
import dateRange from "./icons/date-range.svg";
import location from "./icons/location.svg";
import SuccessPage from "./SuccessPage";
import ErrorPage from "./ErrorPage";

const HomePage = () => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	const [email, setEmail] = useState("");
	const [error, setError] = useState(""); // Estado para errores de validación
	const navigate = useNavigate();

	// Función para validar el email
	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para validar email
		return regex.test(email);
	};

	const handleSubscribe = async () => {
		if (!email) {
			setError("Por favor, introduce tu correo electrónico.");
			return;
		}
		if (!validateEmail(email)) {
			setError("Por favor, introduce un correo electrónico válido.");
			return;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/subscribe`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ email }),
				}
			);

			if (response.ok) {
				navigate("/success");
			} else {
				navigate("/error");
			}
		} catch (error) {
			console.error("Error al suscribir:", error);
			navigate("/error");
		}
	};

	useEffect(() => {
		const targetDate = new Date("2025-02-10T16:00:00"); // Fecha objetivo
		const interval = setInterval(() => {
			const now = new Date();
			const difference = targetDate - now;

			if (difference <= 0) {
				clearInterval(interval);
			} else {
				const days = Math.floor(difference / (1000 * 60 * 60 * 24));
				const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
				const minutes = Math.floor((difference / (1000 * 60)) % 60);
				const seconds = Math.floor((difference / 1000) % 60);

				setTimeLeft({ days, hours, minutes, seconds });
			}
		}, 1000);

		return () => clearInterval(interval); // Limpieza del intervalo
	}, []);

	return (
		<div className="container">
			<header className="header">
				<h1>ALTA LABS & DITELBA</h1>
				<div className="countdown">
					<div className="time-box">
						{timeLeft.days.toString().padStart(2, "0")}
						<p className="subtext">D</p>
					</div>
					<div className="time-box">
						{timeLeft.hours.toString().padStart(2, "0")}
						<p className="subtext">H</p>
					</div>
					<div className="time-box">
						{timeLeft.minutes.toString().padStart(2, "0")}
						<p className="subtext">M</p>
					</div>
					<div className="time-box">
						{timeLeft.seconds.toString().padStart(2, "0")}
						<p className="subtext">S</p>
					</div>
				</div>
			</header>
			<main className="main-content">
				<div>{error && <p className="error-message">{error}</p>}</div>
				<div className="email-section">
					<input
						type="email"
						placeholder="Tu correo electrónico"
						className={`email-input ${error ? "error" : ""}`}
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							setError(""); // Limpiar el error mientras el usuario escribe
						}}
					/>
					<button className="subscribe-button" onClick={handleSubscribe}>
						Suscribirme
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>

				<div className="info">
					<div className="moreinfo">
						<img src={location} alt="Location Icon" />
						<p> Can Valero, C/Assegra 17A. Ditelba Mallorca</p>
					</div>
					<div className="moreinfo">
						<img src={dateRange} alt="Date Range Icon" />
						<p> Lunes, 10 de febrero de 2025, 16:00h</p>
					</div>
				</div>
			</main>
		</div>
	);
};

const App = () => {
	return (
		<Router basename="/">
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/success" element={<SuccessPage />} />
				<Route path="/error" element={<ErrorPage />} />
			</Routes>
		</Router>
	);
};

export default App;
